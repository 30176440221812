import sub1Bg from '@/apps/av/assets/subscription-bg-1.jpg';
import sub2Bg from '@/apps/av/assets/subscription-bg-2.jpg';
import sub3Bg from '@/apps/av/assets/subscription-bg-3.jpg';

import item1Image from '@/apps/av/assets/item.png';

let itemId = 10;

const alternatives = () => [
  {
    id: itemId++,
    name: itemId + 'Блинчики по-домашнему с малиновым джемом',
    picture: item1Image,
    meta: '180 г, 96 ккал',
  },

  {
    id: itemId++,
    name: itemId + 'Аладьи из кабачков с овощами',
    picture: item1Image,
    meta: '210 г, 48 ккал',
  },

  {
    id: itemId++,
    name: itemId + 'Смузи из тропических фруктов',
    picture: item1Image,
    meta: '210 г, 48 ккал',
  },
];

const items = () => [
  {
    id: itemId++,
    name: itemId + 'Блинчики по-домашнему с малиновым джемом',
    picture: item1Image,
    meta: '180 г, 96 ккал',
    alternatives: alternatives(),
  },

  {
    id: itemId++,
    name: itemId + 'Аладьи из кабачков с овощами',
    picture: item1Image,
    meta: '210 г, 48 ккал',
    alternatives: alternatives(),
  },

  {
    id: itemId++,
    name: itemId + 'Смузи из тропических фруктов',
    picture: item1Image,
    meta: '210 г, 48 ккал',
    alternatives: alternatives(),
  },
];

const groups = () =>
  [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ].map((name) => ({ id: itemId++, name, items: items() }));

const sub1 = {
  id: 1,

  title: {
    text: 'Здоровый завтрак',
    color: '#000',
  },

  subtitle: {
    text: '1 390 ₽ / день',
    color: '#000',
  },

  toptitle: {
    text: '1500 кКал',
    color: '#fff',
    backgroundColor: '#80BB3D',
  },

  backgroundImage: sub1Bg,

  items: groups(),
};

const sub2 = {
  id: 2,

  title: {
    text: 'Ваша корзина раз в неделю',
    color: '#000',
  },

  subtitle: {
    text: 'от 5 999 ₽ / неделю',
    color: '#000',
  },

  toptitle: {
    text: '-15% скидка',
    color: '#fff',
    backgroundColor: '#EA0035',
  },

  backgroundImage: sub2Bg,

  items: groups(),
};

const sub3 = {
  id: 3,

  title: {
    text: 'Винные мастер-классы',
    color: '#fff',
  },

  subtitle: {
    text: '5 000 ₽ / месяц',
    color: '#fff',
  },

  backgroundImage: sub3Bg,

  items: groups(),
};

const subscriptions = [sub1, sub2, sub3];

export { subscriptions };

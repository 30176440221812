import Vue from 'vue';
import Vuex from 'vuex';
import mainStore from '@/core/stores/main';
import { STORE_TYPES } from '@/core/data/main';

Vue.use(Vuex);

async function initiateStore(opts = {}) {
  const { storeType } = opts;

  if (storeType === STORE_TYPES.av) {
    const { default: main } = await import('@/apps/av/store/main');
    const { default: menu } = await import('@/apps/av/store/menu');
    const { default: subscriptions } = await import('@/apps/av/store/subscriptions');

    store.registerModule('av$main', main);
    store.registerModule('av$menu', menu);
    store.registerModule('av$subscriptions', subscriptions);
    await store.dispatch('av$main/init');
  } else {
    const { default: main } = await import('@/apps/default/store/main');
    const { default: cart } = await import('@/apps/default/store/cart');
    const { default: categories } = await import('@/apps/default/store/categories');
    const { default: search } = await import('@/apps/default/store/search');

    store.registerModule('default$main', main);
    store.registerModule('default$cart', cart);
    store.registerModule('default$categories', categories);
    store.registerModule('default$search', search);
    await store.dispatch('default$main/init');
  }
}

const store = new Vuex.Store({
  modules: {
    core$main: mainStore,
  },
});

export default store;

export { initiateStore };

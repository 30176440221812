import 'animate.css';
import 'normalize.css';
import 'swiper/css/swiper.css';
import 'shared/core/styles/fonts/roboto.scss';
import '@/core/styles.scss';
import 'regenerator-runtime';
import '@/core/scripts/store';
import { checkVersion } from '@/core/scripts/version-checker';

import Vue from 'vue';
import Vuex from 'vuex';
import { api, bus, Channel, utils } from 'shared/core';
import { auth } from 'shared/modules';
import App from '@/core/App';
import router from '@/core/router';
import VueI18n from 'vue-i18n';
import store from '@/core/store';

(async () => {
  await checkVersion();

  // TODO: move to appropriate place
  localStorage.setItem('TLocale', 'ru');

  Vue.config.productionTip = false;
  Vue.prototype.$api = api;
  Vue.prototype.$bus = bus;
  Vue.use(Vuex);
  Vue.use(VueI18n);

  api.setEndpoint('customer/market');

  utils.getEvents().forEach(({ value }) => value.init(store));
  utils.getChannels().forEach(({ value }) => new Channel(value));

  auth.connect({ store, router, opts: { isPassive: true, type: 'market' } });

  new Vue({
    router,
    store,
    i18n: new VueI18n({
      locale: 'ru',
      messages: {},
    }),
    render: (h) => h(App),
  }).$mount('#app');
})();

import { api } from 'shared/core';

import store from '@/core/store';

const getStoreId = () => store.getters['core$main/storeId'];

class SearchAPI {
  async getForSmall(input = '', opts = {}) {
    const { categoriesPage = 1, categoriesLimit = 20, itemsLimit = 20, categoryId } = opts;

    const payload = {
      items_q: input,
      // use_smart_search: 1,
      page: categoriesPage,
      limit: categoriesLimit,
      items_limit: itemsLimit,
      category_id: categoryId,
      id: getStoreId(),
    };

    if (!categoryId) delete payload.category_id;

    return api.endpoint.get('common/store/catalog/category/get-list-with-items', payload);
  }

  async getForBig(input = '', opts = {}) {
    const { itemsPage = 1, itemsLimit = 20, categoryId } = opts;

    const payload = {
      q: input,
      use_smart_search: 1,
      page: itemsPage,
      limit: itemsLimit,
      category_id: categoryId,
      id: getStoreId(),
    };

    if (!categoryId) delete payload.category_id;

    return api.endpoint.get('common/store/catalog/item/get-list', payload);
  }

  getProducts(input = '', opts = {}) {
    const { page = 1, limit = 20 } = opts;

    return api.endpoint.get('common/store/catalog/item/get-list', {
      page,
      limit,
      q: input,
      use_smart_search: 1,
      id: getStoreId(),
    });
  }
}

export default new SearchAPI();

import Vue from 'vue';
import Router from 'vue-router';
import { STORE_TYPES } from '@/core/data/main';

Vue.use(Router);

async function initiateRoutes(opts = {}) {
  const { storeType } = opts;

  switch (storeType) {
    case STORE_TYPES.small:
    case STORE_TYPES.big:
      {
        const { default: routes } = await import('@/apps/default/routes');
        router.addRoutes(await routes());
      }
      break;

    case STORE_TYPES.av:
      {
        const { default: routes } = await import('@/apps/av/routes');
        router.addRoutes(await routes());
      }
      break;
  }
}

const routes = [];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
});

export default router;

export { initiateRoutes };

import cartAPI from '@/core/api/cart';
import mainAPI from '@/core/api/main';
import { api, bus } from 'shared/core';
import { ITEM_STATUSES } from '@/core/data/main';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import router from '@/core/router';
import { getCartKey } from '@/core/utils/cart';

const state = {
  loading: false,
  order: null,
  pendingItems: {},
  pendingTimers: [],
  canDelivery: null,
  errors: {},
  deliveryType: null,
  deliveryCasesLoading: false,
  deliveryCases: {
    items: [],
    meta: {},
  },
};

const getters = {
  loading: (state) => state.loading,
  order: (state) => state.order,
  deliveryType: (state) => state.deliveryType,
  deliveryCases: (state) => state.deliveryCases.items,
  deliveryCasesLoading: (state) => state.deliveryCasesLoading,
  errors: (state) => state.errors,
  canDelivery: (state) => state.canDelivery,

  values: (state) => (state.order ? state.order.items : []),

  orderItemByItemId: (state) => (id) => {
    if (!state.order) return;

    const orderItem = state.order.items.find((item) => item.item_id === id);
    if (orderItem) return orderItem;
  },

  quantityByItemId: (state) => (id) => {
    if (!state.order) return 0;
    const orderItem = state.order.items.find((item) => item.item_id === id);
    if (orderItem) return orderItem.quantity;

    return 0;
  },

  doesItemHavePendingStatus: (state) => (item, status) => state.pendingItems[item.id] === status,

  doesItemHaveHandledPending: (state) => (item) =>
    state.pendingItems[item.id] === ITEM_STATUSES.HANDLED_LOADING,

  isPendingTimerRegistered: (state) => (id) => state.pendingTimers.includes(id),
};

const actions = {
  async setUpCurrentOrder({ dispatch, rootGetters }) {
    try {
      const orderKey = localStorage.getItem('param$order_key');

      let response;

      try {
        if (orderKey) response = await cartAPI.getOrderByKey({ key: orderKey });
      } catch {}

      if (!response) {
        const payload = {
          store_id: rootGetters['core$main/storeId'],
          key: getCartKey(),
        };

        if (!payload.key) delete payload.key;

        response = await api.endpoint.post('common/order/create', payload);
      }

      await dispatch('setOrderAction', response);
    } catch (error) {
      console.error(error);
    }
  },

  handleSelfDeliveryAction: ({ dispatch }) => {
    mainAPI
      .getProfile()
      .then(async () => {
        try {
          await cartAPI.bindOrder();
        } catch {}

        dispatch('finishSelfDeliveryAction');
      })
      .catch(() => router.replace({ name: 'auth' }));
  },

  handleExternalDeliveryAction: () => {
    mainAPI
      .getProfile()
      .then(async () => {
        try {
          await cartAPI.bindOrder();
        } catch {}

        router.replace({ name: 'delivery' });
      })
      .catch(() => router.replace({ name: 'auth' }));
  },

  setDeliveryTypeAction: ({ commit }, value) => {
    commit('setDeliveryType', value);
  },

  finishDeliveryAction: async ({ commit, getters }, caseId) => {
    if (getters.loading) return;

    try {
      commit('setLoading', true);

      const { id, delivery } = getters.order;
      const deliveryCase = getters.deliveryCases.find((c) => c.id == caseId);

      await cartAPI.saveAddress({ ...delivery, id });
      await cartAPI.selectDeliveryCase({ order_delivery_calculation_id: deliveryCase.id, id });

      let { link } = await cartAPI.getBindCardLink({ id });

      if (link) {
        if (localStorage.getItem('param$type')) {
          link = link + '?type=' + localStorage.getItem('param$type');
        }

        document.location = link;
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },

  finishSelfDeliveryAction: async ({ commit, getters }) => {
    if (getters.loading) return;

    try {
      commit('setLoading', true);

      const { id, delivery } = getters.order;

      await cartAPI.saveAddress({ ...delivery, id });
      await cartAPI.setDelveryBySelf({ id });

      let { link } = await cartAPI.getBindCardLink({ id });

      if (link) {
        if (localStorage.getItem('param$type')) {
          link = link + '?type=' + localStorage.getItem('param$type');
        }

        document.location = link;
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },

  setLoadingAction: ({ commit }, value) => {
    commit('setLoading', value);
  },

  setCanDeliveryAction: ({ commit }, value) => {
    commit('setCanDelivery', value);
  },

  fetchDirectAddressAction: async ({ dispatch }, value) => {
    try {
      await dispatch('setLoadingAction', true);
      await dispatch('setCanDeliveryAction');

      const [target] = await mainAPI.getAddress({ q: value, limit: 1 });

      if (!target) return;

      const { address, coords_lat, coords_lng } = target;

      await dispatch('updateInfoAction', { name: 'address', value: address });
      await dispatch('updateInfoAction', { name: 'lat', value: coords_lat });
      await dispatch('updateInfoAction', { name: 'lng', value: coords_lng });
      await dispatch('handleDirectAddressAction');
    } catch (error) {
      console.error(error);
      dispatch('setLoadingAction', false);
    }
  },

  saveDeliveryCasesAction: async ({ commit }, value) => {
    commit('setDeliveryCases', value);
  },

  handleDirectAddressAction: async ({ commit, dispatch, getters }) => {
    const { delivery } = getters.order;
    const { coords_lat: lat, coords_lng: lng } = delivery;

    try {
      if (!lat || !lng) {
        dispatch('setCanDeliveryAction', false);
        commit('setError', { name: 'address', message: 'Адрес вне зоны доставки' });
        return;
      }

      const { is_available } = await cartAPI.isDeliveryAvailable({ lat, lng });

      dispatch('setCanDeliveryAction', is_available);

      if (!is_available) {
        commit('setError', { name: 'address', message: 'Адрес вне зоны доставки' });
        return;
      }

      commit('setDeliveryCasesLoading', true);
      await cartAPI.saveAddress({ ...delivery, id: getters.order.id });
      await cartAPI.selectAddress({ ...delivery, id: getters.order.id });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('setLoadingAction', false);
    }
  },

  updateInfoAction: ({ commit }, { name, value }) => {
    commit('setError', { name, message: '' });

    if (name === 'address') {
      commit('refreshDeliveryCases');
    }

    switch (name) {
      case 'lng':
        commit('setOrderLng', value);
        return;

      case 'lat':
        commit('setOrderLat', value);
        return;

      case 'address':
        commit('setOrderAddress', value);
        return;

      case 'apartment':
        commit('setOrderApartment', value);
        return;

      case 'entrance':
        commit('setOrderEntrance', value);
        return;

      case 'floor':
        commit('setOrderFloor', value);
        return;

      case 'note':
        commit('setOrderNote', value);
        return;
    }
  },

  setOrderAction: ({ commit }, value) => {
    commit('setOrder', value);
  },

  addWithAmountAction: async ({ commit, state, getters }, opts = {}) => {
    const { item, quantity, addToExistingQuantity = true } = opts;

    if (state.loading) return;
    const orderId = state.order.id;

    commit('setLoading', true);

    const orderItem = getters.orderItemByItemId(item.id);

    try {
      let newOrder;

      if (orderItem) {
        let overallQuantity;

        if (addToExistingQuantity)
          overallQuantity = orderItem ? orderItem.quantity + quantity : quantity;
        else overallQuantity = quantity;

        newOrder = await cartAPI.updateItem(orderId, orderItem, overallQuantity);
      } else {
        newOrder = await cartAPI.addItem(orderId, item, quantity);
      }

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
      bus.emit('categoryItem$resetQuantity', item.id);
    }
  },

  addAction: async ({ commit, state, getters, dispatch }, value) => {
    if (state.loading) return;

    const orderId = state.order.id;
    const orderItem = getters.orderItemByItemId(value.id);

    commit('setLoading', true);

    const timerId = await dispatch('registerPendingCheckout', value);

    try {
      const newOrder = orderItem
        ? await cartAPI.increaseItem(orderId, orderItem)
        : await cartAPI.addItem(orderId, value);

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('unregisterPendingCheckout', { item: value, timerId });
      commit('setLoading', false);
      bus.emit('categoryItem$resetQuantity', value.id);
    }
  },

  addWeightAction: async ({ commit, state, getters, dispatch }, opts) => {
    const { value, weight, addToExistingWeight = false } = opts;

    if (state.loading) return;

    const orderId = state.order.id;
    const orderItem = getters.orderItemByItemId(value.id);

    commit('setLoading', true);

    const timerId = await dispatch('registerPendingCheckout', value);

    try {
      const newOrder = orderItem
        ? await cartAPI.updateWeightItem(
            orderId,
            value,
            addToExistingWeight ? orderItem.weight + weight : weight,
          )
        : await cartAPI.addWeightItem(orderId, value, weight);

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('unregisterPendingCheckout', { item: value, timerId });
      commit('setLoading', false);
      bus.emit('categoryItem$resetQuantity', value.id);
    }
  },

  removeAction: async ({ dispatch, commit, state, getters }, value) => {
    if (state.loading) return;

    const orderId = state.order.id;
    const orderItem = getters.orderItemByItemId(value.id);

    commit('setLoading', true);

    const timerId = await dispatch('registerPendingCheckout', value);

    try {
      const newOrder = await cartAPI.removeItem(orderId, orderItem);

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('unregisterPendingCheckout', { item: value, timerId });
      commit('setLoading', false);
      bus.emit('categoryItem$resetQuantity', value.id);
    }
  },

  removeWeightAction: async ({ dispatch, commit, state, getters }, value) => {
    if (state.loading) return;

    const orderId = state.order.id;
    const orderItem = getters.orderItemByItemId(value.id);

    commit('setLoading', true);

    const timerId = await dispatch('registerPendingCheckout', value);

    try {
      const newOrder = await cartAPI.removeItem(orderId, orderItem);

      commit('setOrder', newOrder);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('unregisterPendingCheckout', { item: value, timerId });
      commit('setLoading', false);
      bus.emit('categoryItem$resetQuantity', value.id);
    }
  },

  registerPendingCheckout: async ({ commit, getters }, item) => {
    commit('setItemPendingStatus', { item, status: ITEM_STATUSES.LOADING });

    const timerId = setTimeout(() => {
      if (getters.doesItemHavePendingStatus(item, ITEM_STATUSES.LOADING)) {
        commit('setItemPendingStatus', { item, status: ITEM_STATUSES.HANDLED_LOADING });
      }
    }, 500);

    commit('addPendingTimer', timerId);

    return timerId;
  },

  unregisterPendingCheckout: async ({ commit, getters }, { item, timerId }) => {
    if (getters.isPendingTimerRegistered(timerId)) {
      commit('removePendingTimer', timerId);
      commit('setItemPendingStatus', { item, status: null });
    }
  },

  afterFinish: () => {
    localStorage.removeItem('cart_key');
  },
};

const mutations = {
  setDeliveryType: (state, value) => {
    state.deliveryType = value;
  },

  setDeliveryCases: (state, value) => {
    state.deliveryCases = value;
  },

  refreshDeliveryCases: (state) => {
    state.deliveryCases = { items: [], meta: {} };
  },

  setOrder: (state, value) => {
    state.order = value;
    if (value.key) localStorage.setItem('cart_key', value.key);
  },

  add: (state, value) => {
    state.values = state.values.concat(value);
  },

  remove: (state, value) => {
    const index = state.values.findIndex((v) => value.id === v.id);
    const values = state.values.slice();
    values.splice(index, 1);
    state.values = values;
  },

  setDeliveryCasesLoading: (state, value) => {
    state.deliveryCasesLoading = value;
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  setItemPendingStatus: (state, { item, status }) => {
    state.pendingItems = {
      ...state.pendingItems,
      [item.id]: status,
    };
  },

  addPendingTimer: (state, timer) => {
    state.pendingTimers = state.pendingTimers.concat(timer);
  },

  removePendingTimer: (state, timer) => {
    state.pendingTimers = state.pendingTimers.filter((t) => t !== timer);
    clearTimeout(timer);
  },

  setCanDelivery: (state, value) => {
    state.canDelivery = value;
  },

  setDelivery: (state, value) => {
    state.order = produce(cloneDeep(state.order), (draft) => {
      draft.delivery = value;
    });
  },

  setOrderEntrance: (state, value) => {
    state.order = produce(cloneDeep(state.order), (draft) => {
      draft.delivery.address_entrance = value;
    });
  },

  setOrderApartment: (state, value) => {
    state.order = produce(cloneDeep(state.order), (draft) => {
      draft.delivery.address_apartment = value;
    });
  },

  setOrderLng: (state, value) => {
    state.order = produce(cloneDeep(state.order), (draft) => {
      draft.delivery.coords_lng = value;
    });
  },

  setOrderLat: (state, value) => {
    state.order = produce(cloneDeep(state.order), (draft) => {
      draft.delivery.coords_lat = value;
    });
  },

  setError: (state, { name, message }) => {
    const errors = { ...state.errors };
    errors[name] = message;
    state.errors = errors;
  },

  setOrderFloor: (state, value) => {
    state.order = produce(cloneDeep(state.order), (draft) => {
      draft.delivery.address_floor = value;
    });
  },

  setOrderAddress: (state, value) => {
    state.order = produce(cloneDeep(state.order), (draft) => {
      draft.delivery.address = value;
    });
  },

  setOrderNote: (state, value) => {
    state.order = produce(cloneDeep(state.order), (draft) => {
      draft.delivery.note = value;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

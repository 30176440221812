// @ts-check

import qs from 'qs';

let [hash] = location.href.split('/').reverse();

const { order_key } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

const params = new URLSearchParams(window.location.search);
const queryParams = params.toString();

if (queryParams) {
  hash = hash.slice(0, hash.indexOf('?'));
  params.forEach((value, key) => localStorage.setItem(`param$${key}`, value));
}

if (order_key) localStorage.setItem(`param$order_key`, order_key);
else localStorage.removeItem(`param$order_key`);

localStorage.setItem('hashedStoreId', hash);

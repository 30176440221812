import item1Image from '@/apps/av/assets/item.png';
import sub1Bg from '@/apps/av/assets/subscription-bg-1.jpg';
import sub2Bg from '@/apps/av/assets/subscription-bg-2.jpg';
import sub3Bg from '@/apps/av/assets/subscription-bg-3.jpg';

import products from '@/apps/av/data/items_3.json';

const articleRegex = /ТОВ/gi;

export function getUnknownItems(rawItems) {
  const rawItemIds = [...new Set(rawItems.map((i) => Number(i.id)))];

  const knownItemIds = products.map(({ article }) => {
    const id = article.replace(articleRegex, '');
    return id;
  });

  const unknown = rawItemIds.filter((id) => !knownItemIds.find((i) => i == id));
  unknown.length && console.error('UNKNOWN ITEMS:', unknown);

  return unknown;
}

export function getRealItem(rawItem) {
  const realItem =
    products.find(({ article }) => {
      const id = article.replace(articleRegex, '');
      return rawItem.id == id;
    }) || {};

  return { ...rawItem, ...realItem };
}

export function getNameForGroup(id) {
  const days = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

  return days[id - 1];
}

export function doProvisionForProduct(product) {
  Object.assign(product, {
    meta: getMetaForProduct(product),
    picture: getImageForProduct(product),
  });

  return product;
}

export function doProvisionForSub(sub) {
  Object.assign(sub, {
    backgroundImage: getBackgroundForSub(sub),
    // toptitle: getToptitleForSub(sub),
    // title: getTitleForSub(sub),
    // subtitle: getSubtitleForSub(sub),
  });

  return sub;
}

function getImageForProduct({ id }) {
  return item1Image;
}

function getMetaForProduct({ id }) {
  return '180 г, 96 ккал';
}

function getToptitleForSub({ id }) {
  return {
    text: '-15% скидка',
    color: '#fff',
    backgroundColor: '#EA0035',
  };
}

function getTitleForSub(sub) {
  return { text: sub.name, color: '#ffffff' };
}

function getSubtitleForSub({ id }) {
  return {
    text: 'от 5 999 ₽ / неделю',
    color: '#000',
  };
}

function getBackgroundForSub({ id }) {
  switch (id) {
    case 1:
      return sub1Bg;

    case 2:
      return sub2Bg;

    case 3:
      return sub3Bg;

    default:
      return sub1Bg;
  }
}

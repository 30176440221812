import axios from 'axios';

import {
  doProvisionForProduct,
  doProvisionForSub,
  getNameForGroup,
  getUnknownItems,
  getRealItem,
} from '@/apps/av/utils/mockHelper';

const state = {
  products: [],
  subs: [],
  activeSubId: null,
  groups: {},
  order: null,
};

const getters = {
  subs: (state) => state.subs,
  subById: (state) => (id) => state.subs.find((sub) => sub.id == id),
  activeSubId: (state) => state.activeSubId,
  groupsBySubId: (state) => (id) => state.groups[id] || [],

  productsBySubId: (state) => ({ sub_id }) => state.products.filter((p) => p.sub_id === sub_id),

  productsByGroupAndSubId: (state) => ({ group_id, sub_id }) =>
    state.products.filter((p) => p.group_id === group_id && p.sub_id === sub_id),

  order: (state) => state.order,
};

const actions = {
  init: async ({ commit }) => {
    const { data: response } = await axios.get(
      'https://api.airtable.com/v0/appxOIGvQD8j2Qi9k/Table%201',
      {
        headers: {
          Authorization: 'Bearer keyCtz5wS4R9kBaEw',
        },
      },
    );

    const subs = [];
    const groups = {};

    // GENERATE PRODUCTS
    let products = response.records.map(({ fields }) => {
      let {
        Product_id: id,
        Subscription_id: sub_id,
        WeekDay: group_id,
        Product_name: name,
      } = fields;

      id = Number(id);
      group_id = Number(group_id);
      sub_id = Number(sub_id);

      return doProvisionForProduct({ id, sub_id, group_id, name });
    });

    getUnknownItems(products);

    products = products.map((p) => getRealItem(p));

    // GENERATE SUBS
    response.records.forEach(({ fields }) => {
      let { Subscription_id: id, Suscription_name: name, Color: color } = fields;

      id = Number(id);

      if (subs.find((s) => s.id === id)) return;

      subs.push(doProvisionForSub({ name, id, color }));
    });

    // GENERATE GROUPS
    response.records.forEach(({ fields }) => {
      let { WeekDay: id, Subscription_id: sub_id } = fields;

      id = Number(id);
      sub_id = Number(sub_id);

      if (!groups[sub_id]) groups[sub_id] = [];
      if (groups[sub_id].find((s) => s.id === id)) return;

      groups[sub_id].push({ id, sub_id, name: getNameForGroup(id) });
    });

    // SORT GROUPS
    Object.keys(groups).forEach((sub_id) => {
      groups[sub_id] = groups[sub_id].sort((a1, a2) => (a1.id < a2.id ? -1 : 1));
    });

    commit('_setProducts', products);
    commit('_setGroups', groups);
    commit('_setSubs', subs);
  },

  setActiveSubId: ({ commit }, value) => {
    commit('_setActiveSubId', value);
  },

  setOrder: ({ commit }, value) => {
    commit('_setOrder', value);
  },
};

const mutations = {
  _setProducts: (s, v) => (s.products = v),
  _setGroups: (s, v) => (s.groups = v),
  _setSubs: (s, v) => (s.subs = v),
  _setActiveSubId: (s, v) => (s.activeSubId = v),
  _setOrder: (s, v) => (s.order = v),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

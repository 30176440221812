const state = {
  open: false,
};

const getters = {
  open: (state) => state.open,
};

const actions = {
  setOpen: ({ commit }, value) => {
    commit('_setOpen', value);
  },
};

const mutations = {
  _setOpen: (state, value) => (state.open = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

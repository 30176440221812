export default async () => [
  {
    path: '/',
    component: () => import('@/apps/av/views/Main'),
    children: [
      {
        path: 'sub-success',
        name: 'sub-success',
        component: () => import('@/apps/av/views/SubscriptionSuccess/SubscriptionSuccess'),
      },

      {
        path: 'sub-form',
        name: 'sub-form',
        component: () => import('@/apps/av/views/SubscriptionForm/SubscriptionForm'),
      },

      {
        path: 'sub',
        name: 'sub',
        component: () => import('@/apps/av/views/Subscription/Subscription'),
      },

      {
        path: 'subs',
        name: 'subs',
        component: () => import('@/apps/av/views/SubscriptionPreviews/SubscriptionPreviews'),
      },

      {
        path: '',
        name: 'main',
        component: () => import('@/apps/av/views/Subscriptions/Subscriptions'),
      },
    ],
  },
];

import mainAPI from '@/core/api/main';

const state = {
  // [id]: {
  //   info: {},
  //   meta: {},
  // },
  allCategories: new Map(),

  // [id]: {
  //   items: [],
  //   meta: {},
  // },
  allProducts: new Map(),

  rootMeta: {},

  fetchLoading: false,
};

const getters = {
  rootCategories: (state) => {
    const result = [];

    for (const value of state.allCategories.values()) {
      !value.info.parent_id && result.push(value);
    }

    return result;
  },

  allCategories: (state) => {
    const result = [];

    for (const value of state.allCategories.values()) {
      result.push(value);
    }

    return result;
  },

  subCategories: (state) => (parentId) => {
    const result = [];

    for (const value of state.allCategories.values()) {
      value.info.parent_id === parentId && result.push(value);
    }

    return result;
  },

  categoriesSize: (state) => state.allCategories.size,

  categoryProducts: (state) => (categoryId) => {
    const target = state.allProducts.get(categoryId);
    return target ? target.items : [];
  },

  productById: (state) => (id) => {
    let result = [];

    for (const value of state.allProducts.values()) {
      result = result.concat(value.items);
    }

    return result.find((p) => p.id === id);
  },

  rootMeta: (state) => state.rootMeta,
  categoryById: (state) => (id) => state.allCategories.get(id),
  size: (state) => state.allCategories.size,
  fetchLoading: (state) => state.fetchLoading,
};

const actions = {
  addRootCategoriesAction: ({ commit }, { categories }) => {
    commit('addCategories', categories.items);
    commit('setRootMeta', categories.meta);
  },

  addCategoriesAction: ({ commit }, { id, items, categories }) => {
    commit('addCategories', categories.items);
    commit('addProducts', { id, products: items });
    commit('setCategoryMeta', { id, meta: categories.meta });
  },

  addInternalCategoriesAction: ({ commit }, { items, meta, id }) => {
    const categories = items.map((item) => item.category);

    commit('addCategories', categories);

    id ? commit('setCategoryMeta', { id, meta }) : commit('setRootMeta', meta);

    items.forEach((item) => {
      const products = { items: item.items, meta: { page: 1, limit: meta.items_limit } };
      commit('addProducts', { id: item.category.id, products });
    });
  },

  // addUncategorizedProductsAction: ({ commit }, { items, meta }) => {
  //   commit('addUncategorizedProducts', { items, meta });
  // },

  fetchRootCategoriesAction: async ({ commit, getters, dispatch, rootGetters }) => {
    try {
      if (state.fetchLoading) return;

      const meta = getters.rootMeta;
      let response;

      commit('setFetchLoading', true);

      if (!meta) {
        const payload = { store_id: rootGetters['core$main/storeId'] };
        response = await mainAPI.getCategoriesWithProducts(payload);
      } else {
        if (meta.page >= meta.total_pages) return;

        const payload = {
          categories_page: meta.page + 1 || 1,
          categories_limit: meta.limit,
          store_id: rootGetters['core$main/storeId'],
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      }

      await dispatch('addRootCategoriesAction', response);

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetchLoading', false);
    }
  },

  fetchInternalCategories: async (
    { state, commit, getters, dispatch, rootGetters },
    { id, limit, items_limit },
  ) => {
    try {
      if (state.fetchLoading) return;

      const { meta } = getters.categoryById(id) || {};

      let response;

      commit('setFetchLoading', true);

      if (!meta) {
        const payload = {
          id: id,
          limit,
          items_limit,
          store_id: rootGetters['core$main/storeId'],
        };

        response = await mainAPI.getCategoriesWithInternalProducts(payload);
      } else {
        if (meta.page >= meta.total_pages) return true;

        const payload = {
          ...meta,
          id: id,
          limit: limit || meta.limit,
          items_limit: items_limit || meta.items_limit,
          page: meta.page + 1 || 1,
          store_id: rootGetters['core$main/storeId'],
        };

        response = await mainAPI.getCategoriesWithInternalProducts(payload);
      }

      if (!response.items.length) return;

      await dispatch('addInternalCategoriesAction', { ...response, id });

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetchLoading', false);
    }
  },

  fetchSubCategoriesAction: async ({ commit, state, getters, dispatch, rootGetters }, id) => {
    try {
      if (state.fetchLoading) return;

      const { meta } = getters.categoryById(id) || {};

      let response;

      commit('setFetchLoading', true);

      if (!meta) {
        const payload = {
          category_id: id,
          store_id: rootGetters['core$main/storeId'],
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      } else {
        if (meta.page >= meta.total_pages) return true;

        const payload = {
          category_id: id,
          categories_page: meta.page + 1 || 1,
          categories_limit: meta.limit,
          store_id: rootGetters['core$main/storeId'],
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      }

      await dispatch('addCategoriesAction', { ...response, id });

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetchLoading', false);
    }
  },

  // fetchUncategorizedProducts: async ({ commit, state, dispatch }) => {
  //   try {
  //     if (state.fetchLoading) return;

  //     const { meta } = state.allProducts.get(0) || { meta: { limit: 1000, page: 1 } };

  //     let response;

  //     commit('setFetchLoading', true);

  //     if (!meta) {
  //       response = await productsAPI.getUncategorizedProducts();
  //     } else {
  //       if (meta.page >= meta.total_pages) return true;

  //       const payload = {
  //         ...meta,
  //         page: meta.page + 1 || 1,
  //         limit: meta.limit,
  //       };

  //       response = await productsAPI.getUncategorizedProducts(payload);
  //     }

  //     await dispatch('addUncategorizedProductsAction', response);

  //     return true;
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     commit('setFetchLoading', false);
  //   }
  // },

  fetchCategoryItems: async ({ commit, dispatch, state, getters, rootGetters }, id) => {
    try {
      if (state.fetchLoading) return;

      const { meta } = state.allProducts.get(id) || {};
      const { meta: categoryMeta } = getters.categoryById(id) || {};

      let response;

      commit('setFetchLoading', true);

      if (!meta) {
        const payload = {
          category_id: id,
          store_id: rootGetters['core$main/storeId'],
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      } else {
        if (meta.page >= meta.total_pages) return true;

        const payload = {
          category_id: id,
          categories_page: categoryMeta.page,
          categories_limit: categoryMeta.limit,
          items_page: meta.page + 1 || 1,
          items_limit: meta.limit,
          store_id: rootGetters['core$main/storeId'],
        };

        response = await mainAPI.getCategoriesWithProducts(payload);
      }

      await dispatch('addCategoriesAction', { ...response, id });

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      commit('setFetchLoading', false);
    }
  },
};

const mutations = {
  addCategories: (state, categories) => {
    const newCategories = new Map();
    categories.forEach((c) => newCategories.set(c.id, { info: c }));

    state.allCategories = new Map([...state.allCategories, ...newCategories]);
  },

  addProducts: (state, { id, products }) => {
    const cached = state.allProducts.get(id);
    const newProducts = new Map([...state.allProducts]);

    if (!cached) {
      newProducts.set(id, products);
      state.allProducts = newProducts;

      return;
    }

    const { items, meta } = products;

    // TODO: maybe remove duplicates
    const updated = {
      items: [...cached.items, ...items],
      meta,
    };

    newProducts.set(id, updated);

    state.allProducts = newProducts;
  },

  setRootMeta: (state, meta) => {
    state.rootMeta = meta;
  },

  setCategoryMeta: (state, { id, meta }) => {
    const category = state.allCategories.get(id);
    category.meta = meta;

    const updatedCategories = new Map([...state.allCategories]);
    updatedCategories.set(id, category);

    state.allCategories = updatedCategories;
  },

  setFetchLoading: (state, value) => {
    if (state.fetchLoading !== value) {
      state.fetchLoading = value;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { api } from 'shared/core';

class MainAPI {
  selectStore = (opts = {}) => {
    const { store_id } = opts;

    const payload = { id: store_id };

    return api.endpoint.post('dashboard/store/selection/select', payload);
  };

  getStoreType(id) {
    return api.endpoint.get('common/store/catalog/get-interface-type', { id });
  }

  getStoreIdByHash(hash) {
    return api.endpoint.get('common/store/get-by-uuid', { uuid: hash });
  }

  getProfile() {
    return api.endpoint.get('dashboard/profile/get');
  }

  async getCategoriesWithProducts(opts = {}) {
    const {
      items_page = 1,
      items_limit = 20,
      categories_page = 1,
      categories_limit = 20,
      category_id,
      store_id,
    } = opts;

    return api.endpoint.get('common/store/catalog/get-categories-and-items', {
      id: store_id,
      items_page,
      items_limit,
      categories_page,
      categories_limit,
      category_id,
    });
  }

  /**
   *
   * @param {{limit?: number, page?: number, items_limit?: number}} opts
   */
  async getCategoriesWithInternalProducts(opts = {}) {
    const { items_limit = 20, limit = 20, page = 1, id, store_id } = opts;

    return api.endpoint.get('common/store/catalog/category/get-list-with-items', {
      items_limit,
      limit,
      page,
      category_id: id,
      id: store_id,
    });
  }

  getAddress = async (opts = {}) => {
    const { q, limit = 10 } = opts;

    return api.endpoint.get('dashboard/profile/delivery-address/find/', {
      q,
      limit,
    });
  };

  getPaycardList = async (opts = {}) => {
    const { page = 1, limit = 999 } = opts;

    return api.endpoint.get('dashboard/card/get-list', { page, limit });
  };

  bindPaycard = async (opts = {}) => {
    return api.endpoint.post('dashboard/card/bind');
  };
}

export default new MainAPI();

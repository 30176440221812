import { STORE_TYPES, INIT_STAGES } from '@/core/data/main';
import mainAPI from '@/core/api/main';
import router from '@/core/router';
import { initiateApp } from '@/core/init';

const state = {
  loading: true,
  type: '',
  store: null,
  init: null,
  storeId: null,
  isCatalogEnabled: null,
  itemsQuantity: 0,
};

const getters = {
  loading: (state) => state.loading,
  type: (state) => state.type,
  isStoreBig: (state) => state.type === STORE_TYPES.big,
  isStoreSmall: (state) => state.type === STORE_TYPES.small,
  store: (state) => state.store,
  isInitRun: (state) => state.init === INIT_STAGES.RUN,
  isInitDone: (state) => state.init === INIT_STAGES.DONE,
  storeId: (state) => state.storeId,
  isCatalogEnabled: (state) => state.isCatalogEnabled,
  itemsQuantity: (state) => state.itemsQuantity,
};

const actions = {
  setLoadingAction: ({ commit }, value) => {
    commit('setLoading', value);
  },

  setStoreId: ({ commit }, value) => {
    commit('_setStoreId', value);
  },

  initAction: async ({ commit, getters, dispatch }) => {
    if (getters.isInitDone) return;
    if (getters.isInitRun) return;

    commit('setInit', INIT_STAGES.RUN);

    try {
      let storeId = Number(process.env.STORE_ID) || 4487;
      const storeIdHash = localStorage.getItem('hashedStoreId');

      if (storeIdHash) {
        try {
          const { id } = await mainAPI.getStoreIdByHash(storeIdHash);
          storeId = id;
        } catch (error) {
          if (!IS_DEV) return router.replace({ name: '__non_existing_link' });
        }
      } else if (!IS_DEV) return router.replace({ name: '__non_existing_link' });

      commit('_setStoreId', storeId);

      const response = await mainAPI.getStoreType(storeId);
      const { is_catalog_enabled, items_quantity } = response;
      let { size } = response;

      commit('_setIsCatalogEnabled', is_catalog_enabled);
      commit('_setItemsQuantity', items_quantity);

      // TODO: IMPORTANT remove IS_DEV condition and storeId
      size = !IS_DEV ? size : storeId == 4487 ? STORE_TYPES.av : size;

      commit('setType', size);

      await initiateApp({ storeType: size });

      dispatch('setLoadingAction', false);

      commit('setInit', INIT_STAGES.DONE);

      if (!is_catalog_enabled) {
        await dispatch('default$cart/handleExternalDeliveryAction', null, { root: true });
      }
    } catch (error) {
      console.error(error);
      // router.replace('/logout').then(() => commit('setInit', null));
    }
  },
};

const mutations = {
  setLoading: (state, value) => {
    state.loading = value;
  },

  setType: (state, value) => {
    state.type = value;
  },

  setInit: (state, value) => {
    state.init = value;
  },

  _setStoreId: (state, value) => {
    state.storeId = value;
  },

  _setIsCatalogEnabled: (state, value) => {
    state.isCatalogEnabled = value;
  },

  _setItemsQuantity: (state, value) => {
    state.itemsQuantity = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { STORE_TYPES } from '@/core/data/main';

const store = {};

const getters = {};

const actions = {
  async init({ dispatch, rootGetters }) {
    await dispatch('default$cart/setUpCurrentOrder', null, { root: true });

    const size = rootGetters['core$main/type'];
    const isCatalogEnabled = rootGetters['core$main/isCatalogEnabled'];

    if (!isCatalogEnabled) return;

    if (size === STORE_TYPES.small) {
      const payload = {
        limit: 1000,
        items_limit: 1000,
      };

      await dispatch('default$categories/fetchInternalCategories', payload, { root: true });
    } else if (size === STORE_TYPES.big) {
      await dispatch('default$categories/fetchRootCategoriesAction', null, { root: true });
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  store,
  getters,
  actions,
  mutations,
};

import { bus, api } from 'shared/core';

class Channel {
  url = '/dashboard/profile/get-channel/';
  endpoint = api.endpoint;

  handler = (ev) => {
    try {
      const message = JSON.parse(ev.data);

      if (message && message.data && message.data.order_deliveries_calculations) {
        bus.emit('default$cart/selectAddressComplete', message.data.order_deliveries_calculations);
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export default new Channel();

import searchAPI from '@/core/api/search';
import { differenceInMilliseconds } from 'date-fns';
import { STORE_TYPES } from '@/core/data/main';

const initialState = () => ({
  input: '',
  loading: false,
  inputLastDateChange: null,
  tryToFetchDataInMilliseconds: 1000,
  categoryId: null,
  registeredTimers: {},
  switcher: 'all',
});

const restoreFoundResults = () => ({
  foundProducts: {
    items: [],
    meta: {},
  },

  foundCategories: {
    items: [],
    meta: {},
  },

  // [id]: {items: [], meta: {}}
  foundCategoryProducts: new Map(),
});

const state = Object.assign(initialState(), restoreFoundResults());

const getters = {
  input: (state) => state.input,

  loading: (state) => state.loading,

  switcher: (state) => state.switcher,

  inputLastDateChange: (state) => state.inputLastDateChange,

  foundProducts: (state) => state.foundProducts.items,

  foundCategories: (state) => state.foundCategories.items,

  foundCategoryProductsById: (state) => (id) => {
    const categoryProducts = state.foundCategoryProducts.get(id) || {};
    if (!categoryProducts.items) return [];
    return categoryProducts.items;
  },

  productById: (state) => (id) => {
    let product = state.foundProducts.items.find((item) => item.id === id);

    if (!product) {
      for (const categoryProducts of state.foundCategoryProducts.values()) {
        const result = categoryProducts.items.find((item) => item.id === id);

        if (result) {
          product = result;
          break;
        }
      }
    }

    return product;
  },

  categoryId: (state) => state.categoryId,

  storeType: (_state, _getters, _rootState, rootGetters) => rootGetters['core$main/type'],

  rootCategoriesSize: (_state, _getters, _rootState, rootGetters) =>
    rootGetters['default$categories/categoriesSize'],

  canSearchInCategory: (_state, getters) => () => {
    if (getters.rootCategoriesSize <= 1) return false;
    if (getters.switcher === 'category') return true;
  },
};

const actions = {
  setInputAction: ({ commit, state, dispatch, getters }, opts = {}) => {
    const { value, type = getters.storeType } = opts;

    if (state.loading) return;

    commit('setInput', value);
    commit('setInputLastDateChange');
    dispatch('registerInputChangeTimeoutAction', { type });
  },

  setCategoryIdAction: ({ commit }, value) => {
    let categoryId = Number(value);
    if (Object.is(categoryId, NaN)) categoryId = undefined;
    commit('setCategoryId', categoryId);
  },

  registerInputChangeTimeoutAction: ({ state, dispatch, commit, getters }, { type }) => {
    const timeoutId = Date.now();

    const timeoutHandler = () => {
      if (state.loading || !state.input || state.input.length < 3) {
        dispatch('clearAllRegisteredTimersAction');
        return;
      }

      const now = new Date();

      // если прошла секунда после последнего ввода
      const canContinue =
        differenceInMilliseconds(now, state.inputLastDateChange) >=
        state.tryToFetchDataInMilliseconds;

      if (!canContinue) return;

      dispatch('clearAllRegisteredTimersAction');

      commit('restore');

      if (STORE_TYPES.small && getters.rootCategoriesSize === 1) {
        dispatch('fetchDataActionForBigStore');
      } else {
        switch (type) {
          case STORE_TYPES.big:
            dispatch('fetchDataActionForBigStore');
            break;

          case STORE_TYPES.small:
            dispatch('fetchDataActionForSmallStore');
            break;
        }
      }
    };

    const timeout = setTimeout(timeoutHandler, state.tryToFetchDataInMilliseconds);

    commit('addRegisteredTimer', { id: timeoutId, timeout });
  },

  clearAllRegisteredTimersAction: ({ state, commit }) => {
    Object.keys(state.registeredTimers).forEach((id) => {
      clearTimeout(state.registeredTimers[id]);
      commit('removeRegisteredTimer', id);
    });
  },

  // initial
  fetchDataActionForBigStore: ({ getters, commit }) => {
    commit('setLoading', true);

    const payload = {
      categoryId: getters.canSearchInCategory() ? getters.categoryId : undefined,
    };

    searchAPI
      .getForBig(getters.input, payload)
      .then((products) => commit('setFoundProducts', products))
      .catch((error) => console.error(error))
      .finally(() => commit('setLoading', false));
  },

  // initial
  fetchDataActionForSmallStore: ({ getters, commit, dispatch }) => {
    commit('setLoading', true);

    const payload = {
      categoryId: getters.canSearchInCategory() ? getters.categoryId : undefined,
    };

    if (getters.canSearchInCategory()) dispatch('fetchDataActionForBigStore');

    searchAPI
      .getForSmall(getters.input, payload)
      .then((result) => dispatch('setFoundCategoriesWithProductsAction', result))
      .catch((error) => console.error(error))
      .finally(() => commit('setLoading', false));
  },

  fetchProducts: ({ state, commit }) => {
    const { meta } = state.foundProducts;

    if (!Object.keys(meta).length) return;
    if (meta.page >= meta.total_pages) return;

    commit('setLoading', true);

    const payload = {
      page: state.foundProducts.meta.page + 1,
      limit: state.foundProducts.meta.limit,
    };

    searchAPI
      .getProducts(state.input, payload)
      .then((products) => commit('addFoundProducts', products))
      .catch((error) => console.error(error))
      .finally(() => commit('setLoading', false));
  },

  fetchCategoriesWithProducts: ({ state, getters, commit, dispatch }) => {
    if (state.loading) return;

    const { meta } = state.foundCategories;

    if (meta.page >= meta.total_pages) return;

    const payload = {
      categoriesPage: meta.page + 1,
      itemsLimit: meta.items_limit,
      categoryId: getters.canSearchInCategory() ? getters.categoryId : undefined,
      categoriesLimit: meta.limit,
    };

    commit('setLoading', true);

    searchAPI
      .getForSmall(getters.input, payload)
      .then((result) => dispatch('setFoundCategoriesWithProductsAction', result))
      .catch((error) => console.error(error))
      .finally(() => commit('setLoading', false));
  },

  fetchCategoryProducts: ({ state, commit }, id) => {
    if (state.loading) return;

    const { meta } = state.foundCategoryProducts.get(id);

    if (meta.page >= meta.total_pages) return;

    const payload = {
      ...meta,
      page: meta.page + 1,
    };

    commit('setLoading', true);

    searchAPI
      .getProducts(state.input, payload)
      .then((products) => commit('addFoundCategoryProducts', { ...products, id }))
      .catch((error) => console.error(error))
      .finally(() => commit('setLoading', false));
  },

  setFoundCategoriesWithProductsAction: ({ commit }, { items, meta }) => {
    const categories = items.map((item) => item.category);

    commit('setFoundCategories', { items: categories, meta });

    items.forEach((item) => {
      if (item.items.length) {
        const products = { id: item.category.id, items: item.items, meta: { page: 1 } };
        commit('addFoundCategoryProducts', products);
      }
    });
  },

  restoreAction: ({ commit }) => {
    commit('restore');
  },

  setSwitcherAction: ({ commit }, value) => {
    commit('setSwitcher', value);
  },
};

const mutations = {
  restore: (state) => {
    Object.assign(state, restoreFoundResults());
  },

  setSwitcher: (state, value) => {
    state.switcher = value;
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  setInput: (state, value) => {
    state.input = value;
  },

  setCategoryId: (state, value) => {
    state.categoryId = value;
  },

  setFoundProducts: (state, value) => {
    state.foundProducts = value;
  },

  setFoundCategories: (state, { items, meta }) => {
    const allCategories = [...state.foundCategories.items, ...items];
    const uniqueIds = new Set(allCategories.map((c) => c.id));
    const updatedCategories = [...uniqueIds].map((id) => allCategories.find((c) => c.id === id));

    state.foundCategories = { items: updatedCategories, meta };
  },

  addFoundProducts: (state, { items, meta }) => {
    const all = [...state.foundProducts.items, ...items];
    const uniqueIds = new Set(all.map((i) => i.id));

    const newItems = [...uniqueIds].map((id) => all.find((i) => i.id === id));

    state.foundProducts = {
      items: newItems,
      meta,
    };
  },

  addFoundCategoryProducts: (state, value) => {
    const { id, items, meta } = value;
    const categoriesWithProducts = new Map([...state.foundCategoryProducts]);
    const categoryProducts = categoriesWithProducts.get(id);

    if (!categoryProducts) {
      categoriesWithProducts.set(id, { items, meta });
      state.foundCategoryProducts = categoriesWithProducts;
      return;
    }

    const allCategoryProducts = [...categoryProducts.items, ...items];
    const uniqueIds = new Set(allCategoryProducts.map((product) => product.id));

    categoryProducts.items = [...uniqueIds].map((id) =>
      allCategoryProducts.find((p) => p.id === id),
    );

    categoryProducts.meta = meta;

    categoriesWithProducts.set(id, categoryProducts);

    state.foundCategoryProducts = categoriesWithProducts;
  },

  addRegisteredTimer: (state, { id, timeout }) => {
    const newRegisteredTimers = { ...state.registeredTimers, [id]: timeout };
    state.registeredTimers = newRegisteredTimers;
  },

  removeRegisteredTimer: (state, id) => {
    const newRegisteredTimers = { ...state.registeredTimers };
    delete newRegisteredTimers[id];
    state.registeredTimers = newRegisteredTimers;
  },

  setInputLastDateChange: (state) => {
    state.inputLastDateChange = new Date();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

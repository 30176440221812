<template>
  <div class="preloader">
    <icon class="icon" />
  </div>
</template>

<script>
import Icon from '!vue-svg-loader!@/apps/default/assets/images/loader.svg';

export default {
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
@keyframes spin {
  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.icon {
  width: 50px;
  height: 50px;
  animation: 2s ease spin infinite;
}
</style>

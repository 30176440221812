import { subscriptions } from '@/apps/av/data/mocks';

const state = {
  items: subscriptions,
  activeId: null,
};

const getters = {
  items: (state) => state.items,
  activeId: (state) => state.activeId,
  activeItemItems: (state) =>
    state.activeId ? state.items.find((i) => i.id === state.activeId).items : [],
};

const actions = {
  setActiveId: ({ commit }, value) => commit('_setActiveId', value),
  setItems: ({ commit }, value) => commit('_setItems', value),
};

const mutations = {
  _setActiveId: (state, value) => (state.activeId = value),
  _setItems: (state, value) => (state.items = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

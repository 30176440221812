<template>
  <div class="main">
    <component :is="computeComponent" />
  </div>
</template>

<script>
import Preloader from '@/core/views/Preloader';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Preloader,
  },

  computed: {
    ...mapGetters({ loading: 'core$main/loading' }),

    computeComponent() {
      if (this.loading) return 'preloader';
      return 'router-view';
    },
  },

  methods: {
    ...mapActions({ initAction: 'core$main/initAction' }),
  },

  mounted() {
    this.initAction();
  },
};
</script>

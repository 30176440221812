import { api } from 'shared/core';
import { getCartKey } from '@/core/utils/cart';

class CartAPI {
  async payByCard(opts = {}) {
    const { order_id, card_id } = opts;

    const payload = { id: order_id, card_id };

    return api.endpoint.post('dashboard/order/payment/pay-by-card', payload);
  }

  async getOrderByKey(opts = {}) {
    const { key: orderKey } = opts;
    return api.endpoint.get('common/order/get-by-key', { key: orderKey });
  }

  async createTemporaryOrder(opts = {}) {
    const { cartKey, store_id } = opts;

    const payload = { store_id };

    if (cartKey) payload.key = cartKey;

    return api.endpoint.post('common/order/create', payload);
  }

  async getActiveOrder() {
    return api.endpoint.post('dashboard/selected-store/order/get-active');
  }

  async saveOrderSubSets(opts = {}) {
    const { sets, order_id } = opts;

    const payload = { id: order_id, order_subscription_sets: sets };

    return api.endpoint.post('dashboard/selected-store/order/subscription-set/save', payload);
  }

  async createRealOrder(opts = {}) {
    const { ordering_type } = opts;

    const payload = {};
    if (ordering_type) payload.ordering_type = ordering_type;

    return api.endpoint.post('dashboard/selected-store/order/create', payload);
  }

  async deleteActiveOrder() {
    const activeOrder = await api.endpoint.get('/dashboard/selected-store/order/create');

    if (activeOrder) {
      await api.endpoint.post('dashboard/selected-store/order/delete', { id: activeOrder.id });
    }
  }

  getUrlForAddCard() {
    return api.endpoint.post('dashboard/card/get-bind-link');
  }

  // MAYBE: юзать перед payByCard
  async bindOrder() {
    const payload = {
      key: getCartKey(),
    };

    return api.endpoint.post('dashboard/order/bind-by-key', payload);
  }

  async setDelveryBySelf(opts = {}) {
    const { id } = opts;

    return api.endpoint.post('dashboard/selected-store/order/delivery/is-self', { id });
  }

  async getBindCardLink(opts = {}) {
    const { id } = opts;

    return api.endpoint.post('dashboard/selected-store/order/send-with-card-selection', { id });
  }

  async getDeliveryCases(id) {
    return api.endpoint.get('dashboard/selected-store/order/delivery/calculation/get-cases', {
      id,
    });
  }

  async selectAddress(opts = {}) {
    const {
      id,
      address,
      address_apartment,
      address_entrance,
      address_floor,
      coords_lat,
      coords_lng,
      note,
    } = opts;

    return api.endpoint.post('dashboard/selected-store/order/delivery/address/select', {
      id,
      address,
      address_apartment,
      address_entrance,
      address_floor,
      coords_lat,
      coords_lng,
      note,
    });
  }

  async saveAddress(opts = {}) {
    const {
      id,
      address,
      address_apartment,
      address_entrance,
      address_floor,
      coords_lat,
      coords_lng,
      note,
    } = opts;

    return api.endpoint.post('dashboard/selected-store/order/delivery/address/save', {
      id,
      address,
      address_apartment,
      address_entrance,
      address_floor,
      coords_lat,
      coords_lng,
      note,
    });
  }

  async selectDeliveryCase(opts = {}) {
    const { id, order_delivery_calculation_id } = opts;

    return api.endpoint.post('dashboard/selected-store/order/delivery/calculation/select', {
      id,
      order_delivery_calculation_id,
    });
  }

  async addItem(orderId, item, quantity = 1, opts = {}) {
    const { cartKey = getCartKey() } = opts;

    return api.endpoint.post('common/order/item/create', {
      id: orderId,
      item_id: item.id,
      item_quantity: quantity,
      key: cartKey,
    });
  }

  async updateItem(orderId, item, quantity = 1, opts = {}) {
    const { cartKey = getCartKey() } = opts;

    return api.endpoint.post('common/order/item/update', {
      id: orderId,
      order_item_id: item.id,
      item_quantity: quantity,
      key: cartKey,
    });
  }

  async removeItem(orderId, item, opts = {}) {
    const { cartKey = getCartKey() } = opts;

    return api.endpoint.post('common/order/item/delete', {
      id: orderId,
      order_item_id: item.id,
      key: cartKey,
    });
  }

  async addWeightItem(orderId, item, weight, opts = {}) {
    const { cartKey = getCartKey() } = opts;

    return api.endpoint.post('common/order/item/save-weight', {
      id: orderId,
      item_id: item.id,
      item_weight: weight,
      key: cartKey,
    });
  }

  async updateWeightItem(orderId, item, weight, opts = {}) {
    const { cartKey = getCartKey() } = opts;

    return api.endpoint.post('common/order/item/save-weight', {
      id: orderId,
      item_id: item.id,
      item_weight: weight,
      key: cartKey,
    });
  }

  async increaseItem(orderId, item, opts = {}) {
    const { cartKey = getCartKey() } = opts;

    return api.endpoint.post('common/order/item/increase', {
      id: orderId,
      order_item_id: item.id,
      key: cartKey,
    });
  }

  async decreaseItem(orderId, item, opts = {}) {
    const { cartKey = getCartKey() } = opts;

    return api.endpoint.post('common/order/item/decrease', {
      id: orderId,
      order_item_id: item.id,
      key: cartKey,
    });
  }

  /**
   * response: { "is_available": true/false }
   */
  isDeliveryAvailable = async (opts = {}) => {
    const { lat, lng } = opts;

    if (!lat) throw new Error('required field', 'lat');
    if (!lng) throw new Error('required field', 'lng');

    return api.endpoint.post('dashboard/selected-store/order/delivery/is-available', {
      coords_lat: lat,
      coords_lng: lng,
    });
  };
}

export default new CartAPI();

export default async () => {
  const { default: store } = await import('@/core/store');
  const isCatalogEnabled = store.getters['core$main/isCatalogEnabled'];

  let base = [
    {
      path: '/',
      component: () => import('@/apps/default/views/Main'),
      children: [
        {
          path: '/search',
          name: 'search',
          component: () => import('@/apps/default/views/Search/Search'),
        },

        {
          path: '/delivery',
          name: 'delivery',
          component: () => import('@/apps/default/views/Delivery/Delivery'),
        },
      ],
    },
  ];

  if (isCatalogEnabled) {
    base = base.concat([
      {
        path: '/cart',
        name: 'cart',
        component: () => import('@/apps/default/views/Cart/Cart'),
      },

      {
        path: '/categories/:category/products',
        name: 'products',
        component: () => import('@/apps/default/views/Products/Products'),
      },

      {
        path: '*',
        name: 'main',
        component: () => import('@/apps/default/views/Categories/Categories'),
      },
    ]);
  }

  return base;
};
